<template>
  <div>
    <vx-card title="Highest Cost" :is-refresh="isLoading">

      <div class="flex space-x-2 items-center mb-5">
        <div class="flex-1">
          <ProyekList class="w-full" @proyekRefreshed="params.id_proyek = $event; getData();" />
        </div>
        <div class="flex-1">
          <vs-select class="w-full vs-select-small" v-model="params.period" @input="onChangePeriod($event)">
            <vs-select-item class="vs-select-small" v-for="(it, index) in ['THIS YEAR', 'THIS MONTH', 'LAST 3 MONTH', 'LAST 6 MONTH']" :key="index" :value="it" :text="it"/>
          </vs-select>
        </div>
      </div>

      <div class="overflow-y-auto" style="height: 430pt">
        <template v-if="data.queryResult.length > 0">
          <div v-for="(item, index) in data.queryResult" :key="index" :class="{'mt-4': index}">
            <div class="flex justify-between">
              <div class="flex flex-col">
                <span class="mb-1">{{ item.nama_coa }}</span>
                <h4 class="text-danger">Rp. {{ item.total | idr }}</h4>
              </div>
            </div>
            <vs-divider/>
          </div>
        </template>

        <!--empty state-->
        <div v-if="data.queryResult.length < 1 && !isLoading" class="w-full h-full flex flex-col items-center justify-center">
          <img alt="" class="w-32 opacity-75" src="@/assets/svg/no-data.svg"/>
          <p class="text-sm mt-3">No data.</p>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import ReportRepository from '@/repositories/reports/report-repository'
import ProyekList from '@/views/pages/dashboard/ProyekList'
import moment from 'moment/moment'

export default {
  name: 'HighestCost',
  components: {
    ProyekList
  },
  mounted () {
    this.initData()
  },
  data () {
    return {
      isLoading: false,
      params: {
        id_proyek: null,
        period: 'THIS YEAR',
        start_date: moment().startOf('year').format('YYYY-MM-DD'),
        end_date: moment().endOf('year').format('YYYY-MM-DD')
      },
      data: {
        queryResult: []
      }
    }
  },
  methods: {
    initData () {
      this.getData()
    },

    onChangePeriod (period) {
      if (period === 'THIS YEAR') {
        this.params.start_date = moment().startOf('year').format('YYYY-MM-DD')
        this.params.end_date = moment().endOf('year').format('YYYY-MM-DD')
      } else if (period === 'THIS MONTH') {
        this.params.start_date = moment().startOf('month').format('YYYY-MM-DD')
        this.params.end_date = moment().endOf('month').format('YYYY-MM-DD')
      } else if (period === 'LAST 3 MONTH') {
        this.params.start_date = moment().subtract(3, 'months').startOf('month').format('YYYY-MM-DD')
        this.params.end_date = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
      } else if (period === 'LAST 6 MONTH') {
        this.params.start_date = moment().subtract(6, 'months').startOf('month').format('YYYY-MM-DD')
        this.params.end_date = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
      }

      this.getData()
    },

    getData () {
      this.isLoading = true

      const reportId = 64
      const params = { params: this.params }
      return ReportRepository.templateData(reportId, params)
        .then(response => {
          this.data = response.data
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>
